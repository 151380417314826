html,
body,
#root,
.app {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}
.ocd-div,
.ocd,
.openseadragon {
  width: 100%;
  height: 100%;
}

.ocd-div {
  position: relative;
}
